$color-primary: #002F87;

$color-primary-light: #EBF1FD;
$color-primary-light-2: #3C83F6;

$color-secondary: #526489;
$color-tertiary: #FF671D;

$background-color: #FFF;


$font-family:  "Source Sans Pro", sans-serif;
$default-font-size: 3rem;

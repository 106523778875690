.bgContainerBlue{
  background-color: $color-primary;
}

.color-primary {
  color: $color-primary;
}

.color-primary-light {
  color: $color-primary-light;
}

.color-primary-light-2 {
  color: $color-primary-light-2;
}

.color-secondary {
  color: $color-secondary;
}

.color-tertiary {
  color: $color-tertiary;
}


.top-40 {
  top: 40% !important;
}

.top-33 {
  top: 33% !important;
}



/// Personalindo el p-tabview de primeNg///
.p-tabview {
  font-family: $font-family;

  li {
    width: 50%;
    text-align: center;

    .p-tabview-nav-link {
      // background-color: red;
      display: block;
      color: $color-primary;
      border-color: white;
      border-bottom-width: 0.3rem;
      font-weight: 400;

      /*
        Le puse el important para deshabilitar el box-shadow por culpa de la especificidad que contiene el selector por defecto de primeNg
      */
      &:not(.p-disabled):focus {
        box-shadow: none !important;
      }


    }
  }

  .ng-star-inserted.p-highlight {
    .p-tabview-nav-link {
      border-color: $color-primary-light-2;
    }
  }

}

///
/// Personalizamos el color de los botones de navegación del p-galleria de primeNg
///
.p-galleria {
  & .p-galleria-item-nav {
    color: tint(#000, 50);

    &:not(.p-disabled):hover {
      color: inherit;
    }
  }
}


///
/// Modificamos el color cuando la clase es igual al .ng-invalid en el formulario del cliente
///
.formulario-cliente {
  .ng-invalid,
  .ng-invalid .p-dropdown {
    border-color: #e24c4c;
  }
}

///
/// Modificamos la orientación del los botones de navegación del p-galleria unicamente en la página de del detalle-producto.component.html
///
.detail-phones__img-container {

  button.p-ripple.p-element.p-galleria-item-prev.p-galleria-item-nav.p-link.ng-star-inserted,
  button.p-ripple.p-element.p-galleria-item-next.p-galleria-item-nav.p-link.ng-star-inserted {
    margin-left: -3rem !important;
    margin-top: -2rem;
    font-family: $font-family;
    font-size: 2rem;
  }

  button.p-ripple.p-element.p-galleria-item-next.p-galleria-item-nav.p-link.ng-star-inserted {
    margin-right: -3rem;
  }
}

.p-link:focus {
  box-shadow: none;
}

.p-button.p-button-icon-only.p-button-rounded.backButton {
  width: initial;
  height: initial;
}


////
/// Cambio del tamaño de la fuente en el dropdown si se encuenta en el contenedor .filter-container__dropdown-container
///
.filter-container__dropdown-container {
  .p-inputtext {
    font-size: 0.8rem !important;
  }

  .p-dropdown-clear-icon.p-icon {
    top: .5rem;
  }

  ///
  /// Código para cambiar el padding de los placeholder de los dropdown del filtro de en la pagina de sucursale-page.html
  ///
  @at-root .sucursales-container__dropdown-container {
    .p-dropdown .p-dropdown-label.p-placeholder {
      padding-right: 0;
    }
  }
}

////
/// Cambio de dirección del ícono de limpieza del dropdown para el filtro de sucursales
///
.filter-container___dropdown-container {
  .p-dropdown-clear-icon.p-icon {
    top: 9px;
  }
}

///
/// Cambiar la familia de fuente a los .p-inputtext
///
.p-inputtext {
  font-family: $font-family;
}



///
/// Cambiamos el padding de las clases .p-dialog y del .p-dialog-footer que nos ofrece el tema lara-light-blue
/// UNICAMENTE si contiene la clase welcome-term como contenedor
///

.welcome-terms {
  .p-dialog-footer {
    padding: 1.5rem;
  }
}

.linkButton {
  border: none;
  color: $color-primary;
  font-weight: 700;
  background-color: inherit;

  &:hover {
    text-decoration: underline;
  }
}

/*Estilo para el contenedor del formulario de registro*/
.registerCard{
  border-radius: 1em !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.75) !important;
  -webkit-box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.75);
}
/*Estilo para el contenedor del equipo */
.productCard{
  cursor: pointer !important;
}
.productCard.pcard.p-card-body.p-card-content{
  padding-top: 0 !important;
}
.logoRegisterContainer {
  background-image: url("/assets/images/bg-image.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.primaryButton{
  background: #08458E !important;
  border: 0 !important
}

.app-text-blue{
  color: #083364;
}
.calendarDisplay{
  display: flex !important;
}

/*Personalización de boton volver*/
.backButton{
  background-color: transparent !important;
  border: 0 !important;
}

.customSideBar{
  height: 100vh !important;
  width: 100vw !important;
  /* border-top-left-radius: 1em; */
  /* border-top-right-radius: 1em; */
}

/* Ocultar flechas input number
Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 -webkit-appearance: none !important;
 margin: 0 !important;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #08458E #08458E;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #08458E;
}

*::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: 2px solid white;
}



.max-width-500 {
max-width: 500px;
}

.p-button.p-button-link:focus {
outline: none;
box-shadow: none !important;
}


div.p-virtualscroller-item.ng-star-inserted:not(:last-of-type) {
background-color: #083364 !important;
margin-bottom: 3rem !important;
}

div.p-virtualscroller-list.p-scroller.ng-star-inserted {
background-color: #083364 !important;
}

div.ngx-loading-text.center-center {
margin-top: 10px !important;
width: 100% !important;
text-align: center !important;
font-weight: 700 !important;
font-size: 1rem !important;
line-height: 1.6 !important;
}

@media only screen and (min-width: 768px ) {
.height-md-400 {
  height: 400px;
}
}

.text-green {
color: #1caa3e;
}

.h-screen90 {
height: 90vh !important;
}
